import React from "react";

type SortDirection = "asc" | "desc";

interface ISortButton {
  onChange?: (direction: SortDirection) => void;
  active?: boolean;
  direction?: SortDirection;
}

export default function SortButton({
  active,
  direction = "asc",
  onChange,
}: ISortButton) {
  const onClick = () => {
    const newDirection =
      active === true ? (direction === "asc" ? "desc" : "asc") : direction;
    return onChange && onChange(newDirection);
  };
  return (
    <button
      type="button"
      className={`btn btn-sm ms-1 ${active ? " btn-secondary" : "btn-light"}`}
      onClick={onClick}
    >
      <i
        className={`bi ${
          direction === "asc" ? "bi-arrow-up" : "bi-arrow-down"
        }`}
      />
    </button>
  );
}
