import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { gql } from "@apollo/client/core";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";


// https://www.apollographql.com/docs/react/networking/authentication/

const LOGIN_GQL = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;

export default function LoginPage() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [errorMessage, setError] = useState("");
  const [login] = useMutation(LOGIN_GQL);


  return (
    <Row>
      <Col md={6}>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              const { data } = await login({ variables: formData });
              localStorage.setItem("token", data.login);
              navigate("/");
            } catch (e: any) {
              if (e && e.message === "User credentials are invalid") {
                setError(e.message);
              }
            }
          }}
        >
          {errorMessage && <Alert variant={"danger"}>{errorMessage}</Alert>}
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Username"
              onChange={(e) => {
                setFormData({ ...formData, username: e.target.value });
              }}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
            />
          </Form.Group>

          <div className="mt-3">
            <Button variant="primary" type="submit">
              Log in
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
}
