import React from "react";
import Select from "react-select";
import { SecurityPricePeriod } from "../../../graphql";

type PeriodOption = {
  label: string;
  value: string;
};

type SecurityPeriodSelectProps = {
  value: SecurityPricePeriod;
  onPeriodChange: (period: SecurityPricePeriod) => void;
};
export function SecurityPeriodSelect({
  onPeriodChange,
  value,
}: SecurityPeriodSelectProps) {
  const options = [
    { label: "Week", value: SecurityPricePeriod.Week },
    { label: "Month", value: SecurityPricePeriod.Month },
    { label: "Quarter", value: SecurityPricePeriod.Quarter },
    { label: "Year", value: SecurityPricePeriod.Year },
  ];

  const onChange = (option: PeriodOption | null) => {
    if (option) {
      onPeriodChange(option.value as SecurityPricePeriod);
    }
  };
  const defaultValue = options.find(
    ({ value: optionValue }) => optionValue === value
  );

  return (
    <Select defaultValue={defaultValue} onChange={onChange} options={options} />
  );
}
