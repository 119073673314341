import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  MoneyValue: any;
  SerializedMoney: any;
};

/** Bond security */
export type Bond = Security & {
  /** Security country */
  country?: Maybe<Country>;
  currency: Currency;
  exchange?: Maybe<Exchange>;
  /** Exchange code where the security is traded */
  exchangeCode?: Maybe<ExchangeCode>;
  figi: Scalars['String'];
  name: Scalars['String'];
  /** Security sector */
  sector?: Maybe<SecuritySector>;
  stats?: Maybe<SecurityPeriodStat>;
  ticker: Scalars['String'];
  /** Security type (share, etf, etc) */
  type: InstrumentType;
  uuid: Scalars['ID'];
};


/** Bond security */
export type BondStatsArgs = {
  period: SecurityPricePeriod;
};

export type BondFilter = {
  countries?: InputMaybe<Array<Scalars['String']>>;
  exchange?: InputMaybe<ExchangeCode>;
  name?: InputMaybe<Scalars['String']>;
  sectorIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type BondSort = {
  direction: SortDirection;
  field: BondSortField;
};

export enum BondSortField {
  MonthlyPriceChangePercent = 'MONTHLY_PRICE_CHANGE_PERCENT',
  MonthlyVolatility = 'MONTHLY_VOLATILITY',
  MonthlyVolumeChangePercent = 'MONTHLY_VOLUME_CHANGE_PERCENT',
  Name = 'NAME',
  QuarterlyPriceChangePercent = 'QUARTERLY_PRICE_CHANGE_PERCENT',
  QuarterlyVolatility = 'QUARTERLY_VOLATILITY',
  QuarterlyVolumeChangePercent = 'QUARTERLY_VOLUME_CHANGE_PERCENT',
  WeeklyPriceChangePercent = 'WEEKLY_PRICE_CHANGE_PERCENT',
  WeeklyVolatility = 'WEEKLY_VOLATILITY',
  WeeklyVolumeChangePercent = 'WEEKLY_VOLUME_CHANGE_PERCENT',
  YearlyPriceChangePercent = 'YEARLY_PRICE_CHANGE_PERCENT',
  YearlyVolatility = 'YEARLY_VOLATILITY',
  YearlyVolumeChangePercent = 'YEARLY_VOLUME_CHANGE_PERCENT'
}

export type Country = {
  /** Country code */
  code: Scalars['String'];
  /** Country name */
  name: Scalars['String'];
};

export type CountryFilter = {
  instrumentType: InstrumentType;
};

export enum Currency {
  Chf = 'Chf',
  Cny = 'Cny',
  Eur = 'Eur',
  Gbp = 'Gbp',
  Hkd = 'Hkd',
  Jpy = 'Jpy',
  Kzt = 'Kzt',
  Rub = 'Rub',
  Try = 'Try',
  Usd = 'Usd'
}

/** ETF security */
export type Etf = Security & {
  /** Security country */
  country?: Maybe<Country>;
  currency: Currency;
  exchange?: Maybe<Exchange>;
  /** Exchange code where the security is traded */
  exchangeCode?: Maybe<ExchangeCode>;
  figi: Scalars['String'];
  name: Scalars['String'];
  /** Security sector */
  sector?: Maybe<SecuritySector>;
  stats?: Maybe<SecurityPeriodStat>;
  ticker: Scalars['String'];
  /** Security type (share, etf, etc) */
  type: InstrumentType;
  uuid: Scalars['ID'];
};


/** ETF security */
export type EtfStatsArgs = {
  period: SecurityPricePeriod;
};

export type EtfFilter = {
  countries?: InputMaybe<Array<Scalars['String']>>;
  exchange?: InputMaybe<ExchangeCode>;
  name?: InputMaybe<Scalars['String']>;
  sectorIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type EtfSort = {
  direction: SortDirection;
  field: EtfSortField;
};

export enum EtfSortField {
  MonthlyPriceChangePercent = 'MONTHLY_PRICE_CHANGE_PERCENT',
  MonthlyVolatility = 'MONTHLY_VOLATILITY',
  MonthlyVolumeChangePercent = 'MONTHLY_VOLUME_CHANGE_PERCENT',
  Name = 'NAME',
  QuarterlyPriceChangePercent = 'QUARTERLY_PRICE_CHANGE_PERCENT',
  QuarterlyVolatility = 'QUARTERLY_VOLATILITY',
  QuarterlyVolumeChangePercent = 'QUARTERLY_VOLUME_CHANGE_PERCENT',
  WeeklyPriceChangePercent = 'WEEKLY_PRICE_CHANGE_PERCENT',
  WeeklyVolatility = 'WEEKLY_VOLATILITY',
  WeeklyVolumeChangePercent = 'WEEKLY_VOLUME_CHANGE_PERCENT',
  YearlyPriceChangePercent = 'YEARLY_PRICE_CHANGE_PERCENT',
  YearlyVolatility = 'YEARLY_VOLATILITY',
  YearlyVolumeChangePercent = 'YEARLY_VOLUME_CHANGE_PERCENT'
}

export type Exchange = {
  /** Exchange code */
  code: ExchangeCode;
  /** Exchange ID */
  id: Scalars['ID'];
  /** Exchange name */
  name: Scalars['String'];
};

export enum ExchangeCode {
  Moex = 'MOEX',
  Otc = 'OTC',
  Rts = 'RTS'
}

export enum InstrumentType {
  Bond = 'Bond',
  Currency = 'Currency',
  Etf = 'Etf',
  Share = 'Share'
}

export type Node = {
  /** Unique ID */
  id: Scalars['ID'];
};

export type OffsetPaginationInput = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type Query = {
  bonds: Array<Bond>;
  bondsCount: Scalars['Int'];
  etfs: Array<Etf>;
  etfsCount: Scalars['Int'];
  securityCountries: Array<Country>;
  securitySectors: Array<SecuritySector>;
  shares: Array<Share>;
  sharesCount: Scalars['Int'];
};


export type QueryBondsArgs = {
  filter: BondFilter;
  pagination: OffsetPaginationInput;
  sort: BondSort;
};


export type QueryBondsCountArgs = {
  filter: BondFilter;
};


export type QueryEtfsArgs = {
  filter: EtfFilter;
  pagination: OffsetPaginationInput;
  sort: EtfSort;
};


export type QueryEtfsCountArgs = {
  filter: EtfFilter;
};


export type QuerySecurityCountriesArgs = {
  filter: CountryFilter;
};


export type QuerySharesArgs = {
  filter: ShareFilter;
  pagination: OffsetPaginationInput;
  sort: ShareSort;
};


export type QuerySharesCountArgs = {
  filter: ShareFilter;
};

export type Security = {
  /** Currency in which the security is traded */
  currency: Currency;
  /** Exchange code where the security is traded */
  exchangeCode?: Maybe<ExchangeCode>;
  /** Security Name */
  name: Scalars['String'];
  /** Security type (share, etf, etc) */
  type: InstrumentType;
  /** Unique ID */
  uuid: Scalars['ID'];
};

export type SecurityPeriodStat = {
  lastClose: Scalars['MoneyValue'];
  lastVolume: Scalars['MoneyValue'];
  maxPrice: Scalars['MoneyValue'];
  minPrice: Scalars['MoneyValue'];
  priceChangePercent: Scalars['Float'];
  volatility?: Maybe<Scalars['Float']>;
  volumeChangePercent: Scalars['Float'];
};

export enum SecurityPricePeriod {
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type SecuritySector = {
  /** SecuritySector code */
  code: Scalars['String'];
  /** SecuritySector ID */
  id: Scalars['ID'];
  /** SecuritySector name */
  name: Scalars['String'];
};

/** Share security */
export type Share = Security & {
  /** Security country */
  country?: Maybe<Country>;
  currency: Currency;
  exchange?: Maybe<Exchange>;
  exchangeCode?: Maybe<ExchangeCode>;
  figi: Scalars['String'];
  name: Scalars['String'];
  /** Security sector */
  sector?: Maybe<SecuritySector>;
  stats?: Maybe<SecurityPeriodStat>;
  ticker: Scalars['String'];
  type: InstrumentType;
  uuid: Scalars['ID'];
};


/** Share security */
export type ShareStatsArgs = {
  period: SecurityPricePeriod;
};

export type ShareFilter = {
  countries?: InputMaybe<Array<Scalars['String']>>;
  exchange?: InputMaybe<ExchangeCode>;
  name?: InputMaybe<Scalars['String']>;
  sectorIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type ShareSort = {
  direction: SortDirection;
  field: SharesSortField;
};

export enum SharesSortField {
  MonthlyPriceChangePercent = 'MONTHLY_PRICE_CHANGE_PERCENT',
  MonthlyVolatility = 'MONTHLY_VOLATILITY',
  MonthlyVolumeChangePercent = 'MONTHLY_VOLUME_CHANGE_PERCENT',
  Name = 'NAME',
  QuarterlyPriceChangePercent = 'QUARTERLY_PRICE_CHANGE_PERCENT',
  QuarterlyVolatility = 'QUARTERLY_VOLATILITY',
  QuarterlyVolumeChangePercent = 'QUARTERLY_VOLUME_CHANGE_PERCENT',
  WeeklyPriceChangePercent = 'WEEKLY_PRICE_CHANGE_PERCENT',
  WeeklyVolatility = 'WEEKLY_VOLATILITY',
  WeeklyVolumeChangePercent = 'WEEKLY_VOLUME_CHANGE_PERCENT',
  YearlyPriceChangePercent = 'YEARLY_PRICE_CHANGE_PERCENT',
  YearlyVolatility = 'YEARLY_VOLATILITY',
  YearlyVolumeChangePercent = 'YEARLY_VOLUME_CHANGE_PERCENT'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type BondsQueryVariables = Exact<{
  filter: BondFilter;
  pagination: OffsetPaginationInput;
  sort: BondSort;
  period: SecurityPricePeriod;
}>;


export type BondsQuery = { bonds: Array<{ uuid: string, name: string, figi: string, type: InstrumentType, ticker: string, exchangeCode?: ExchangeCode | null, currency: Currency, sector?: { id: string, name: string } | null, country?: { name: string, code: string } | null, stats?: { volatility?: number | null, maxPrice: any, minPrice: any, priceChangePercent: number, volumeChangePercent: number, lastVolume: any, lastClose: any } | null }> };

export type BondsCountQueryVariables = Exact<{
  filter: BondFilter;
}>;


export type BondsCountQuery = { bondsCount: number };

export type EtfsQueryVariables = Exact<{
  filter: EtfFilter;
  pagination: OffsetPaginationInput;
  sort: EtfSort;
  period: SecurityPricePeriod;
}>;


export type EtfsQuery = { etfs: Array<{ uuid: string, name: string, figi: string, type: InstrumentType, ticker: string, exchangeCode?: ExchangeCode | null, currency: Currency, exchange?: { code: ExchangeCode } | null, sector?: { id: string, name: string } | null, country?: { name: string, code: string } | null, stats?: { volatility?: number | null, maxPrice: any, minPrice: any, priceChangePercent: number, volumeChangePercent: number, lastVolume: any, lastClose: any } | null }> };

export type EtfsCountQueryVariables = Exact<{
  filter: EtfFilter;
}>;


export type EtfsCountQuery = { etfsCount: number };

export type SecurityCountriesQueryVariables = Exact<{
  filter: CountryFilter;
}>;


export type SecurityCountriesQuery = { securityCountries: Array<{ code: string, name: string }> };

export type SecuritySectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type SecuritySectorsQuery = { securitySectors: Array<{ id: string, name: string }> };

export type SharesQueryVariables = Exact<{
  filter: ShareFilter;
  pagination: OffsetPaginationInput;
  sort: ShareSort;
  period: SecurityPricePeriod;
}>;


export type SharesQuery = { shares: Array<{ uuid: string, name: string, figi: string, ticker: string, type: InstrumentType, exchangeCode?: ExchangeCode | null, currency: Currency, sector?: { id: string, name: string } | null, country?: { name: string, code: string } | null, stats?: { volatility?: number | null, maxPrice: any, minPrice: any, priceChangePercent: number, volumeChangePercent: number, lastVolume: any, lastClose: any } | null }> };

export type SharesCountQueryVariables = Exact<{
  filter: ShareFilter;
}>;


export type SharesCountQuery = { sharesCount: number };


export const BondsDocument = gql`
    query bonds($filter: BondFilter!, $pagination: OffsetPaginationInput!, $sort: BondSort!, $period: SecurityPricePeriod!) {
  bonds(filter: $filter, pagination: $pagination, sort: $sort) {
    uuid
    name
    figi
    type
    ticker
    exchangeCode
    sector {
      id
      name
    }
    currency
    country {
      name
      code
    }
    stats(period: $period) {
      volatility
      maxPrice
      minPrice
      priceChangePercent
      volumeChangePercent
      lastVolume
      lastClose
    }
  }
}
    `;

/**
 * __useBondsQuery__
 *
 * To run a query within a React component, call `useBondsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBondsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBondsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useBondsQuery(baseOptions: Apollo.QueryHookOptions<BondsQuery, BondsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BondsQuery, BondsQueryVariables>(BondsDocument, options);
      }
export function useBondsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BondsQuery, BondsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BondsQuery, BondsQueryVariables>(BondsDocument, options);
        }
export type BondsQueryHookResult = ReturnType<typeof useBondsQuery>;
export type BondsLazyQueryHookResult = ReturnType<typeof useBondsLazyQuery>;
export type BondsQueryResult = Apollo.QueryResult<BondsQuery, BondsQueryVariables>;
export const BondsCountDocument = gql`
    query bondsCount($filter: BondFilter!) {
  bondsCount(filter: $filter)
}
    `;

/**
 * __useBondsCountQuery__
 *
 * To run a query within a React component, call `useBondsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBondsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBondsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBondsCountQuery(baseOptions: Apollo.QueryHookOptions<BondsCountQuery, BondsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BondsCountQuery, BondsCountQueryVariables>(BondsCountDocument, options);
      }
export function useBondsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BondsCountQuery, BondsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BondsCountQuery, BondsCountQueryVariables>(BondsCountDocument, options);
        }
export type BondsCountQueryHookResult = ReturnType<typeof useBondsCountQuery>;
export type BondsCountLazyQueryHookResult = ReturnType<typeof useBondsCountLazyQuery>;
export type BondsCountQueryResult = Apollo.QueryResult<BondsCountQuery, BondsCountQueryVariables>;
export const EtfsDocument = gql`
    query etfs($filter: EtfFilter!, $pagination: OffsetPaginationInput!, $sort: EtfSort!, $period: SecurityPricePeriod!) {
  etfs(filter: $filter, pagination: $pagination, sort: $sort) {
    uuid
    name
    figi
    type
    ticker
    exchangeCode
    exchange {
      code
    }
    sector {
      id
      name
    }
    currency
    country {
      name
      code
    }
    stats(period: $period) {
      volatility
      maxPrice
      minPrice
      priceChangePercent
      volumeChangePercent
      lastVolume
      lastClose
    }
  }
}
    `;

/**
 * __useEtfsQuery__
 *
 * To run a query within a React component, call `useEtfsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEtfsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEtfsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useEtfsQuery(baseOptions: Apollo.QueryHookOptions<EtfsQuery, EtfsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EtfsQuery, EtfsQueryVariables>(EtfsDocument, options);
      }
export function useEtfsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EtfsQuery, EtfsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EtfsQuery, EtfsQueryVariables>(EtfsDocument, options);
        }
export type EtfsQueryHookResult = ReturnType<typeof useEtfsQuery>;
export type EtfsLazyQueryHookResult = ReturnType<typeof useEtfsLazyQuery>;
export type EtfsQueryResult = Apollo.QueryResult<EtfsQuery, EtfsQueryVariables>;
export const EtfsCountDocument = gql`
    query etfsCount($filter: EtfFilter!) {
  etfsCount(filter: $filter)
}
    `;

/**
 * __useEtfsCountQuery__
 *
 * To run a query within a React component, call `useEtfsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEtfsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEtfsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEtfsCountQuery(baseOptions: Apollo.QueryHookOptions<EtfsCountQuery, EtfsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EtfsCountQuery, EtfsCountQueryVariables>(EtfsCountDocument, options);
      }
export function useEtfsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EtfsCountQuery, EtfsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EtfsCountQuery, EtfsCountQueryVariables>(EtfsCountDocument, options);
        }
export type EtfsCountQueryHookResult = ReturnType<typeof useEtfsCountQuery>;
export type EtfsCountLazyQueryHookResult = ReturnType<typeof useEtfsCountLazyQuery>;
export type EtfsCountQueryResult = Apollo.QueryResult<EtfsCountQuery, EtfsCountQueryVariables>;
export const SecurityCountriesDocument = gql`
    query securityCountries($filter: CountryFilter!) {
  securityCountries(filter: $filter) {
    code
    name
  }
}
    `;

/**
 * __useSecurityCountriesQuery__
 *
 * To run a query within a React component, call `useSecurityCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityCountriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSecurityCountriesQuery(baseOptions: Apollo.QueryHookOptions<SecurityCountriesQuery, SecurityCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SecurityCountriesQuery, SecurityCountriesQueryVariables>(SecurityCountriesDocument, options);
      }
export function useSecurityCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecurityCountriesQuery, SecurityCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SecurityCountriesQuery, SecurityCountriesQueryVariables>(SecurityCountriesDocument, options);
        }
export type SecurityCountriesQueryHookResult = ReturnType<typeof useSecurityCountriesQuery>;
export type SecurityCountriesLazyQueryHookResult = ReturnType<typeof useSecurityCountriesLazyQuery>;
export type SecurityCountriesQueryResult = Apollo.QueryResult<SecurityCountriesQuery, SecurityCountriesQueryVariables>;
export const SecuritySectorsDocument = gql`
    query securitySectors {
  securitySectors {
    id
    name
  }
}
    `;

/**
 * __useSecuritySectorsQuery__
 *
 * To run a query within a React component, call `useSecuritySectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecuritySectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecuritySectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecuritySectorsQuery(baseOptions?: Apollo.QueryHookOptions<SecuritySectorsQuery, SecuritySectorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SecuritySectorsQuery, SecuritySectorsQueryVariables>(SecuritySectorsDocument, options);
      }
export function useSecuritySectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecuritySectorsQuery, SecuritySectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SecuritySectorsQuery, SecuritySectorsQueryVariables>(SecuritySectorsDocument, options);
        }
export type SecuritySectorsQueryHookResult = ReturnType<typeof useSecuritySectorsQuery>;
export type SecuritySectorsLazyQueryHookResult = ReturnType<typeof useSecuritySectorsLazyQuery>;
export type SecuritySectorsQueryResult = Apollo.QueryResult<SecuritySectorsQuery, SecuritySectorsQueryVariables>;
export const SharesDocument = gql`
    query shares($filter: ShareFilter!, $pagination: OffsetPaginationInput!, $sort: ShareSort!, $period: SecurityPricePeriod!) {
  shares(filter: $filter, pagination: $pagination, sort: $sort) {
    uuid
    name
    figi
    ticker
    type
    exchangeCode
    sector {
      id
      name
    }
    currency
    country {
      name
      code
    }
    stats(period: $period) {
      volatility
      maxPrice
      minPrice
      priceChangePercent
      volumeChangePercent
      lastVolume
      lastClose
    }
  }
}
    `;

/**
 * __useSharesQuery__
 *
 * To run a query within a React component, call `useSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useSharesQuery(baseOptions: Apollo.QueryHookOptions<SharesQuery, SharesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharesQuery, SharesQueryVariables>(SharesDocument, options);
      }
export function useSharesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharesQuery, SharesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharesQuery, SharesQueryVariables>(SharesDocument, options);
        }
export type SharesQueryHookResult = ReturnType<typeof useSharesQuery>;
export type SharesLazyQueryHookResult = ReturnType<typeof useSharesLazyQuery>;
export type SharesQueryResult = Apollo.QueryResult<SharesQuery, SharesQueryVariables>;
export const SharesCountDocument = gql`
    query sharesCount($filter: ShareFilter!) {
  sharesCount(filter: $filter)
}
    `;

/**
 * __useSharesCountQuery__
 *
 * To run a query within a React component, call `useSharesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSharesCountQuery(baseOptions: Apollo.QueryHookOptions<SharesCountQuery, SharesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharesCountQuery, SharesCountQueryVariables>(SharesCountDocument, options);
      }
export function useSharesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharesCountQuery, SharesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharesCountQuery, SharesCountQueryVariables>(SharesCountDocument, options);
        }
export type SharesCountQueryHookResult = ReturnType<typeof useSharesCountQuery>;
export type SharesCountLazyQueryHookResult = ReturnType<typeof useSharesCountLazyQuery>;
export type SharesCountQueryResult = Apollo.QueryResult<SharesCountQuery, SharesCountQueryVariables>;