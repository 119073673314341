import React from "react";
import Select from "react-select";
import { useSecuritySectorsQuery } from "../../../graphql";
import GqlLoaderBar from "../../../application/loaders/Loader/GqlLoader";

type SectorOption = {
  label: string;
  value: string;
};

type SectorSelectProps = {
  value: number[] | undefined;
  onSectorChange: (sectorIds: number[]) => void;
};
export function SectorSelect({ onSectorChange, value }: SectorSelectProps) {
  const [selectedOptions, setSelectedOptions] = React.useState<
    readonly SectorOption[]
  >([]);
  const { data, loading, error } = useSecuritySectorsQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });
  if (loading || error) {
    return <GqlLoaderBar loading={loading} error={error} />;
  }
  if (!data) {
    return null;
  }
  const options = data.securitySectors.map((sector) => ({
    label: sector.name,
    value: sector.id,
  }));
  const defaultValue: SectorOption[] = [];
  options.forEach((option) => {
    if (value && value.includes(parseInt(option.value, 10))) {
      defaultValue.push(option);
    }
  });
  const onChange = (options: readonly SectorOption[]) => {
    setSelectedOptions(options);
    onSectorChange(options.map((option) => parseInt(option.value, 10)));
  };

  return (
    <Select
      placeholder={"Sectors"}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      isOptionDisabled={() => selectedOptions.length >= 3}
      closeMenuOnSelect={false}
      isMulti
    />
  );
}
