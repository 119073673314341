import React from "react";
import Pagination from "react-bootstrap/Pagination";

type TablePaginationProps = {
  currentPage: number;
  total: number;
  pagesSize: number;
  pagesToShow?: number;
  onChange?: (page: number) => void;
};

export default function TablePagination(props: TablePaginationProps) {
  const { total, currentPage, pagesToShow = 10, pagesSize, onChange } = props;
  const totalPages = Math.max(1, Math.ceil(total / pagesSize));
  const firstPageToShow = Math.max(
    1,
    currentPage - Math.floor(pagesToShow / 2)
  );
  const lastPageToShow = Math.min(
    totalPages,
    firstPageToShow + pagesToShow - 1
  );
  let items = [];
  if (lastPageToShow - firstPageToShow < 2) {
    return null;
  }

  const onClick = (e: React.SyntheticEvent) => {
    const link = e.currentTarget as HTMLLinkElement;
    const pageNumber = link.dataset.page;
    if (!pageNumber) {
      throw new Error("Page number is not defined");
    }
    const page = parseInt(pageNumber, 10);
    if (onChange) {
      onChange(page);
    }
  };

  if (firstPageToShow > 1 && total) {
    items.push(
      <Pagination.First key={"first_page"} onClick={onClick} data-page={1} />
    );
  }

  for (let number = firstPageToShow; number <= lastPageToShow; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={onClick}
        data-page={number}
      >
        {number}
      </Pagination.Item>
    );
  }
  if (lastPageToShow !== totalPages) {
    items.push(
      <Pagination.Last
        key={"last_page"}
        onClick={onClick}
        data-page={totalPages}
      />
    );
  }

  return <Pagination>{items}</Pagination>;
}
