import React from "react";
import { Nav } from "react-bootstrap";
import Header from "../Header/Header";
import "./MainLayout.scss";
import { Outlet } from "react-router-dom";
import { Footer } from "../Footer/Footer";

function MainLayout() {
  // let navigate = useNavigate();
  // let matches = useMatches();
  // const loginPage = matches.find((m) => m.pathname === "/login");
  // const token = localStorage.getItem("token");
  // useEffect(() => {
  //   if (!token && !loginPage) {
  //     navigate("/login");
  //   }
  // });

  return (
    <>
      <Header />

      <div className={"container"}>
        <Nav className="" defaultActiveKey="/">
          <Nav.Link href="/securities/shares">Shares</Nav.Link>
          <Nav.Link href="/securities/bonds">Bonds</Nav.Link>
          <Nav.Link href="/securities/etfs">ETFs</Nav.Link>
        </Nav>

        <div className={"pt-4 mb-3"}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MainLayout;
