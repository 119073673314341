import React from "react";
import Select from "react-select";
import { InstrumentType, useSecurityCountriesQuery } from "../../../graphql";
import GqlLoaderBar from "../../../application/loaders/Loader/GqlLoader";

type CountryOption = {
  label: string;
  value: string;
};

type CountrySelectProps = {
  onCountryChange: (countryCodes: string[]) => void;
  value: string[] | undefined;
};
export function CountrySelect({ onCountryChange, value }: CountrySelectProps) {
  const [selectedOptions, setSelectedOptions] = React.useState<
    readonly CountryOption[]
  >([]);
  const { data, loading, error } = useSecurityCountriesQuery({
    variables: {
      filter: {
        instrumentType: InstrumentType.Share,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  if (loading || error) {
    return <GqlLoaderBar loading={loading} error={error} />;
  }
  if (!data) {
    return null;
  }
  const countries = data.securityCountries.map((country) => ({
    label: country.name,
    value: country.code,
  }));
  const onChange = (options: readonly CountryOption[]) => {
    setSelectedOptions(options);
    onCountryChange(options.map((option) => option.value));
  };
  const defaultValue: CountryOption[] = [];
  countries.forEach((country) => {
    if (value && value.includes(country.value)) {
      defaultValue.push(country);
    }
  });

  return (
    <Select
      defaultValue={defaultValue}
      placeholder={"Countries"}
      onChange={onChange}
      options={countries}
      isOptionDisabled={() => selectedOptions.length >= 3}
      closeMenuOnSelect={false}
      isMulti
    />
  );
}
