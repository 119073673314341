import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as React from "react";
import { CountrySelect } from "./CountrySelect";
import { SectorSelect } from "./SectorSelect";
import { SecurityPeriodSelect } from "./SecurityPeriodSelect";
import { ExchangeCode, SecurityPricePeriod } from "../../graphql";
import { ExchangeSelect } from "./ExchangeSelect";
import "./SecuritySearchForm.scss"

export type SecurityFormData = {
  name?: string;
  countries?: string[];
  sectorIds?: number[];
  period: SecurityPricePeriod;
  exchange?: ExchangeCode;
};

type WithEmptyFields<T, F extends keyof T> = T & {
  [P in F]: T[P] | "";
};

type SecuritySearchFormProps = {
  onChange: (search: SecurityFormData) => void;
  values: SecurityFormData;
};
export const SecuritySearchForm = ({
  onChange,
  values,
}: SecuritySearchFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SecurityFormData>();
  if (Object.keys(errors).length > 0) {
    console.log(errors);
  }

  const onCountryChange = (countryCodes: string[]) => {
    setValue("countries", countryCodes);
  };
  const onSectorChange = (sectorIds: number[]) => {
    setValue("sectorIds", sectorIds);
  };
  const onPeriodChange = (period: SecurityPricePeriod) => {
    setValue("period", period);
  };
  const onExchangeChange = (exchange: ExchangeCode) => {
    setValue("exchange", exchange);
  };

  const onChangeWrapper = (data: WithEmptyFields<SecurityFormData, "name">) => {
    const fields: SecurityFormData = {
      name: data.name ? data.name : undefined,
      countries: data.countries ? data.countries : undefined,
      sectorIds: data.sectorIds ? data.sectorIds : undefined,
      period: data.period,
      exchange: data.exchange ? data.exchange : undefined,
    };
    onChange(fields);
  };

  return (
    <Form onSubmit={handleSubmit(onChangeWrapper)} className={"security-search-form"}>
      <Row>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="name">
            <input
              type={"hidden"}
              {...register("period")}
              defaultValue={values.period}
            />
            <SecurityPeriodSelect
              value={values.period}
              onPeriodChange={onPeriodChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="name">
            <input type={"hidden"} {...register("sectorIds")} />
            <SectorSelect
              value={values.sectorIds}
              onSectorChange={onSectorChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Control
              className={"lh-lg"}
              type="text"
              placeholder="Name"
              size={"sm"}
              defaultValue={values.name}
              {...register("name")}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="name">
            <input type={"hidden"} {...register("countries")} />
            <CountrySelect
              value={values.countries}
              onCountryChange={onCountryChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="name">
            <input
              type={"hidden"}
              {...register("exchange")}
              defaultValue={values.exchange}
            />
            <ExchangeSelect
              value={values.exchange}
              onExchangeChange={onExchangeChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <div className="d-grid gap-2 d-md-block">
            <Button variant="primary" type="submit" size={"sm"}>
              <i className={`bi bi-search`} />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
