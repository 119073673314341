import * as React from "react";
import LoaderBar from "./LoaderBar";

interface IProps {
    error: any;
    loading: boolean;
    silent?: boolean;
}

export default function GqlLoaderBar(props: IProps) {
    const silent = props.silent ?? false;
    const { error, loading } = props;
    if (loading) {
        return silent ? null : <LoaderBar />;
    }
    if (error || !silent) {
        throw new Error(
            "Cannot fetch graphql endpoint" + JSON.stringify(error)
        );
    }
    return null;
}
