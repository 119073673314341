import "./SharesList.scss";
import { SharesQuery } from "../../graphql";
import { ShareTile } from "./ShareTile/ShareTile";
import { Col, Row } from "react-bootstrap";
import React from "react";
import TablePagination from "../../application/tables/TablePagination/TablePagination";
import SortButton from "../../application/tables/Sort/SortButton";

type SortDirection = "asc" | "desc";

export enum TableSortField {
  PriceChangePercent = "priceChangePercent",
  Volatility = "volatility",
  VolumeChangePercent = "volumeChangePercent",
  Name = "name",
}

export type ShareListSort = {
  direction: "asc" | "desc";
  field: TableSortField;
};

type SharesListProps = {
  data: SharesQuery["shares"];
  currentPage: number;
  pageSize: number;
  total: number;
  sort: ShareListSort;
  onChangePage?: (pageNumber: number) => void;
  onChangeSort: (field: ShareListSort) => void;
};

const defaultSortDirections: Record<TableSortField, SortDirection> = {
  [TableSortField.Name]: "asc",
  [TableSortField.PriceChangePercent]: "desc",
  [TableSortField.VolumeChangePercent]: "desc",
  [TableSortField.Volatility]: "desc",
};

const ShareSortButton = ({
  name,
  activeSort,
  onChangeSort,
}: {
  name: TableSortField;
  activeSort: ShareListSort;
  onChangeSort: (field: ShareListSort) => void;
}) => {
  const isActiveSort = activeSort.field === name;
  const direction = isActiveSort
    ? (activeSort.direction.toLowerCase() as SortDirection)
    : defaultSortDirections[name];

  return (
    <SortButton
      active={isActiveSort}
      direction={direction}
      onChange={(direction: SortDirection) => {
        onChangeSort({
          field: name,
          direction,
        });
      }}
    />
  );
};

export function SharesList({
  data,
  total,
  currentPage,
  onChangePage,
  onChangeSort,
  pageSize,
  sort,
}: SharesListProps) {
  return (
    <div className={"shares-list"}>
      <Row className={"share-tile-headers"}>
        <Col md={6}>
          Name
          <ShareSortButton
            name={TableSortField.Name}
            activeSort={sort}
            onChangeSort={onChangeSort}
          />
        </Col>
        <Col md={2} className={"number"}>
          Price
          <ShareSortButton
            name={TableSortField.PriceChangePercent}
            activeSort={sort}
            onChangeSort={onChangeSort}
          />
        </Col>
        <Col md={2} className={"number"}>
          Volume
          <ShareSortButton
            name={TableSortField.VolumeChangePercent}
            activeSort={sort}
            onChangeSort={onChangeSort}
          />
        </Col>
        <Col md={2} className={"number"}>
          Volatility
          <ShareSortButton
            name={TableSortField.Volatility}
            activeSort={sort}
            onChangeSort={onChangeSort}
          />
        </Col>
      </Row>

      {data.map((share) => (
        <ShareTile key={share.uuid} security={share} />
      ))}

      <div className={"text-end"}>
        <strong>Total:</strong> {total}
      </div>
      <TablePagination
        total={total}
        pagesSize={pageSize}
        currentPage={currentPage}
        onChange={onChangePage}
      />
    </div>
  );
}
