import React from "react";
import "./App.css";
import { ApolloProvider } from "@apollo/client";
import AppRouter from "../Router/Router";
import buildApolloClient from "../../libs/graphql/apolloClient";

function App() {
  return (
    <React.StrictMode>
      <ApolloProvider client={buildApolloClient()}>
        <AppRouter />
      </ApolloProvider>
    </React.StrictMode>
  );
}

export default App;
