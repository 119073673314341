import React from "react";
import { Col, Row } from "react-bootstrap";

export default function PageNotFound() {
  return (
    <Row>
      <Col md={6}>
        <h1>Page not found</h1>
      </Col>
    </Row>
  );
}