import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "../layout/MainLayout/MainLayout";
import LoginPage from "../authentication/LoginPage";
import PageNotFound from "./ErrorPage/ErrorPage";
import { Etfs } from "../../securities/Etfs";
import { Shares } from "../../securities/Shares";
import { Bonds } from "../../securities/Bonds";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    // errorElement: <PageNotFound />,
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/",
        element: <Shares />,
      },
      {
        path: "/securities",
        children: [
          {
            index: true,
            path: "/securities/shares",
            element: <Shares />,
          },
          {
            path: "/securities/etfs",
            element: <Etfs />,
          },
          {
            path: "/securities/bonds",
            element: <Bonds />,
          },
        ],
      },
      { path: "*", element: <PageNotFound /> },
    ],
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
