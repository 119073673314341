import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

export default function AppHeader() {
  return (
    <header className="app-header">
      <Link to={"/"} className="logo h5 my-0 me-md-auto fw-normal">
        Tendsup
      </Link>
    </header>
  );
}
