import { ApolloClient, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import apolloCache from "./apolloCache";

export default function buildApolloClient() {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
  });
  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
  return new ApolloClient({
    cache: apolloCache,
    link: authLink.concat(httpLink),
  });
}
