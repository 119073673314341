import * as React from "react";
import { Money } from "../../../libs/money/Money";
import { numberFormat } from "../../../libs/formaters/number";

export default function MoneyValue({
  price,
  withCurrency = true,
}: {
  price: Money;
  withCurrency?: boolean;
}) {
  const v = withCurrency
    ? price.format()
    : numberFormat(price.toUnsafeNumber());
  return <>{v}</>;
}
