import Big from "big.js";
import { Currency, currencyByCode } from "./Currency";
import { Currency as CurrencyName } from "../../graphql";

const NUM_DECIMAL_PLACES = 8;

/**
 * The `Money` type is used to represent a monetary value in a specific currency. It allows for safe arithmetic
 * with 8 decimal places of precision.
 *
 * Amounts are represented in their fractional unit.
 * E.g. USD is represented in dollars.
 *
 * Within the API codebase we should always represent monetary values using the `Money` type. The only
 * exception is when we are serializing/deserializing `Money` values to/from our database, GraphQL API, or a 3rd party API.
 *
 * The Money type is immutable. Calling any function on a Money object will not mutate the data, but instead return a new object.
 */
export class Money {
  public readonly amount: Big;
  private readonly currency: Currency;

  public constructor(
    amount: Big | number,
    public readonly currencyCode: CurrencyName
  ) {
    this.amount = new Big(amount);
    const currency = currencyByCode[currencyCode];
    if (!currency) {
      throw new Error(`Invalid currency code: ${currencyCode}`);
    }
    this.currency = currency;
  }

  /**
   * Converts the amount to a JavaScript Number. This is marked as unsafe because it can lead to loss of precision
   * with very large numbers.
   *
   * Note: This method should be used very rarely.
   *
   * E.g.
   *
   * $100.99 outputs 100.99
   *
   * $100.12345678 outputs 100.12345678
   */
  public toUnsafeNumber(): number {
    return this.amount.toNumber();
  }

  public dividedBy(divisor: Money | number): Money {
    if (typeof divisor === "number") {
      return new Money(this.amount.div(divisor), this.currencyCode);
    }

    if (this.currencyCode !== divisor.currencyCode) {
      throw new Error(
        `Cannot divide Money amounts if not the same currency! ${this.currencyCode} != ${divisor.currencyCode}`
      );
    }

    return new Money(this.amount.div(divisor.amount), this.currencyCode);
  }

  public equals(other: Money): boolean {
    if (this.currencyCode !== other.currencyCode) {
      throw new Error(
        `Cannot compare Money amounts if not the same currency! ${this.currencyCode} != ${other.currencyCode}`
      );
    }

    return this.amount.cmp(other.amount) === 0;
  }

  /**
   * Stringified version of the amount including the currency symbol
   * and the standard number of decimals for the currency.
   *
   * E.g.
   *
   *  $1,000.99
   *
   *  €1,000.99
   *
   *  ¥123,456 (0 decimal currency)
   *
   *  KWD 1,234.567 (3 decimal currency)
   *
   */
  public format(): string {
    const currencyFormatter = new Intl.NumberFormat("en", {
      currency: this.currencyCode,
      notation: "standard",
      style: "currency",
    });

    return currencyFormatter.format(
      this.amount.round(this.currency.standardNumberOfDigits).toNumber()
    );
  }

  /**
   * Compact stringified version of the amount including the currency symbol
   * rounded to an easily readable number
   *
   * E.g.
   *
   *  $100.99 outputs $101
   *
   *  $1500.99 outputs $1.5K
   *
   *  $10,000.99 outputs $10K
   *
   *  €1500.99 outputs €1.5K
   *
   *  ¥123,456 outputs ¥123K (0 decimal currency)
   *
   *  KWD 1,234.567 outputs KWD 1.23K (3 decimal currency)
   */
  public formatCompact(): string {
    const currencyFormatter = new Intl.NumberFormat("en", {
      currency: this.currencyCode,
      notation: "compact",
      style: "currency",
    });

    return currencyFormatter.format(
      this.amount.round(this.currency.standardNumberOfDigits).toNumber()
    );
  }

  public isGreaterThan(other: Money): boolean {
    if (this.currencyCode !== other.currencyCode) {
      throw new Error(
        `Cannot compare Money amounts if not the same currency! ${this.currencyCode} != ${other.currencyCode}`
      );
    }

    return this.amount.cmp(other.amount) === 1;
  }

  public isLessThan(other: Money): boolean {
    if (this.currencyCode !== other.currencyCode) {
      throw new Error(
        `Cannot compare Money amounts if not the same currency! ${this.currencyCode} != ${other.currencyCode}`
      );
    }

    return this.amount.cmp(other.amount) === -1;
  }

  public isPositive(): boolean {
    return this.amount.cmp(new Big(0)) > -1;
  }

  public isZero(): boolean {
    return this.amount.cmp(new Big(0)) === 0;
  }

  private ensureSameCurrency(other: Money): void {
    if (this.currencyCode !== other.currencyCode) {
      throw new Error(
        `Cannot minus Money amounts if not the same currency! ${this.currencyCode} != ${other.currencyCode}`
      );
    }
  }

  public minus(other: Money): Money {
    this.ensureSameCurrency(other);

    return new Money(this.amount.minus(other.amount), this.currencyCode);
  }

  public multipliedBy(multiplier: number): Money {
    return new Money(this.amount.mul(multiplier), this.currencyCode);
  }

  public plus(other: Money): Money {
    this.ensureSameCurrency(other);

    return new Money(this.amount.plus(other.amount), this.currencyCode);
  }

  /**
   * Stringified version of the amount with 8 decimal places of precision.
   *
   * E.g.
   *
   * $100.12345678 outputs '100.12345678'
   */
  public toFullPrecision(): string {
    return this.amount.toFixed(NUM_DECIMAL_PLACES);
  }

  /**
   * Stringified version of the amount with the standard number of decimal places for the currency.
   * Rounding is done using "banker's rounding".
   *
   * E.g.
   *
   * $100.12345678 outputs '100.12'
   *
   * ¥123 outputs '123' (0 decimal currency)
   *
   * BWD 100.12345678 outputs '100.123' (3 decimal currency)
   */
  public toStandardPrecision() {
    return this.amount.toFixed(this.currency.standardNumberOfDigits);
  }
}
