import { InMemoryCache } from "@apollo/client";
import { SharesQuery, SharesQueryVariables } from "../../graphql";

const apolloCache = new InMemoryCache({
  typePolicies: {
    ShareSecurity: {
      keyFields: ["uuid"],
    },
    Query: {
      fields: {
        shares: {
          keyArgs: false,
          read(existing: SharesQuery["shares"], args) {
            const variable = args.args as SharesQueryVariables;
            const offset = variable.pagination.offset ?? 0;
            const limit = variable.pagination.limit ?? 20;
            return existing && existing?.slice(offset, offset + limit);
          },
          merge(
            existing: SharesQuery["shares"],
            incoming: SharesQuery["shares"],
            args
          ) {
            const variable = args.args as SharesQueryVariables;
            const offset = variable.pagination.offset ?? 0;
            const merged = existing ? existing.slice(0) : [];
            incoming = incoming ?? [];
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          },
        },
      },
    },
  },
});

export default apolloCache;
