import { Currency as CurrencyName } from "../../graphql";

export const isValidCurrencyCode = (
  currencyCode: string
): currencyCode is CurrencyName => {
  return Object.values(CurrencyName).includes(currencyCode as CurrencyName);
};

export const currencyByCode: {
  [code in CurrencyName]: Currency;
} = {
  [CurrencyName.Chf]: {
    code: CurrencyName.Chf,
    standardNumberOfDigits: 2,
  },
  [CurrencyName.Cny]: {
    code: CurrencyName.Cny,
    standardNumberOfDigits: 2,
  },
  [CurrencyName.Eur]: {
    code: CurrencyName.Eur,
    standardNumberOfDigits: 2,
  },
  [CurrencyName.Gbp]: {
    code: CurrencyName.Gbp,
    standardNumberOfDigits: 2,
  },
  [CurrencyName.Hkd]: {
    code: CurrencyName.Hkd,
    standardNumberOfDigits: 2,
  },
  [CurrencyName.Jpy]: {
    code: CurrencyName.Jpy,
    standardNumberOfDigits: 0,
  },
  [CurrencyName.Rub]: {
    code: CurrencyName.Rub,
    standardNumberOfDigits: 2,
  },
  [CurrencyName.Try]: {
    code: CurrencyName.Try,
    standardNumberOfDigits: 2,
  },
  [CurrencyName.Usd]: {
    code: CurrencyName.Usd,
    standardNumberOfDigits: 2,
  },
  [CurrencyName.Kzt]: {
    code: CurrencyName.Kzt,
    standardNumberOfDigits: 2,
  },
};

export type Currency = {
  readonly code: CurrencyName;
  readonly standardNumberOfDigits: 0 | 2 | 3;
};
