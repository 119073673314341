import "./ShareTile.scss";
import { BondsQuery, EtfsQuery, SharesQuery } from "../../../graphql";
import { ShareExternalLink } from "../ShareExternalLink";
import { Money } from "../../../libs/money/Money";
import MoneyValue from "../../../application/money/MoneyValue/MoneyValue";
import React from "react";
import { numberFormat } from "../../../libs/formaters/number";
import { Col, Row } from "react-bootstrap";
import PercentChanged from "../../../application/money/PercentChanged/PercentChanged";

type Security =
  | SharesQuery["shares"][number]
  | BondsQuery["bonds"][number]
  | EtfsQuery["etfs"][number];

export const ShareTile = ({ security }: { security: Security }) => {
  const price = new Money(security.stats?.lastClose, security.currency);
  const volumeChangePercent = security.stats?.volumeChangePercent
    ? Math.round(security.stats?.volumeChangePercent * 100) / 100
    : null;
  const priceChangePercent = security.stats?.priceChangePercent
    ? Math.round(security.stats?.priceChangePercent * 100) / 100
    : null;

  const volatility = security.stats?.volatility
    ? Math.round(security.stats?.volatility * 100) / 100
    : null;

  return (
    <Row className={"share-tile"}>
      <Col md={6} sm={12}>
        <ShareExternalLink name={security.name} ticker={security.ticker} />
        <div className={"secondary"}>
          {security.country?.code} {security.exchangeCode}{" "}
          {security.sector?.name}
        </div>
        <div className={"secondary"}>
          Daily Close: <MoneyValue price={price} /> Daily Volume:{" "}
          {numberFormat(security.stats?.lastVolume)}
        </div>
      </Col>
      <Col md={6} sm={12}>
        <Row>
          <Col md={4} sm={4} xs={4} className={"number"}>
            <div className={"sm-label"}>Price:</div>
            {priceChangePercent && (
              <PercentChanged value={priceChangePercent} />
            )}
          </Col>
          <Col md={4} sm={4} xs={4} className={"number"}>
            <div className={"sm-label"}>Volume:</div>
            {volumeChangePercent && (
              <PercentChanged value={volumeChangePercent} />
            )}
          </Col>
          <Col md={4} sm={4} xs={4} className={"number"}>
            <div className={"sm-label"}>Volatility:</div>
            {volatility && numberFormat(volatility)}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
