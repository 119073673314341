import {
  ExchangeCode,
  SecurityPricePeriod,
  EtfSortField,
  SortDirection,
 EtfSort,
  useEtfsQuery,
  useEtfsCountQuery,
} from "../../graphql";
import GqlLoaderBar from "../../application/loaders/Loader/GqlLoader";
import React, { useState } from "react";
import {
  ShareListSort,
  SharesList,
  TableSortField,
} from "../ShareList/SharesList";
import { useSearchParams } from "react-router-dom";
import SecuritySearchForm from "../SecuritySearchForm";
import { SecurityFormData } from "../SecuritySearchForm/SecuritySearchForm";

const DEFAULT_PAGE_SIZE = 20;

const fetchSortParams = (
  sortField: string | null,
  securityPricePeriod: SecurityPricePeriod
): EtfSort => {
  if (sortField) {
    const [field, direction] = sortField.split(".");
    const allowedFields = Object.values(EtfSortField);
    if (allowedFields.includes(field as EtfSortField)) {
      return {
        field: field as EtfSortField,
        direction:
          direction === SortDirection.Asc
            ? SortDirection.Asc
            : SortDirection.Desc,
      };
    }
  }

  switch (securityPricePeriod) {
    case SecurityPricePeriod.Week:
      return {
        field: EtfSortField.WeeklyPriceChangePercent,
        direction: SortDirection.Desc,
      };
    case SecurityPricePeriod.Month:
      return {
        field: EtfSortField.MonthlyPriceChangePercent,
        direction: SortDirection.Desc,
      };
    case SecurityPricePeriod.Quarter:
      return {
        field: EtfSortField.QuarterlyPriceChangePercent,
        direction: SortDirection.Desc,
      };
    case SecurityPricePeriod.Year:
      return {
        field: EtfSortField.YearlyPriceChangePercent,
        direction: SortDirection.Desc,
      };
  }
};

const fetchFilter = (searchParams: URLSearchParams): SecurityFormData => {
  return {
    name: searchParams.get("name") || undefined,
    sectorIds: searchParams.get("sectorIds")
      ? searchParams
        .get("sectorIds")
        ?.split("|")
        .map((id) => parseInt(id, 10))
      : undefined,
    countries: searchParams.get("countries")
      ? searchParams.get("countries")?.split("|")
      : undefined,
    period: searchParams.get("period")
      ? (searchParams.get("period") as SecurityPricePeriod)
      : SecurityPricePeriod.Month,
    exchange: searchParams.get("exchange")
      ? (searchParams.get("exchange") as ExchangeCode)
      : undefined,
  };
};

const searchFormToGqlVariables = (filter: SecurityFormData) => {
  const { period, ...fields } = filter;

  return {
    filter: fields,
    period: period,
  };
};

const parsePage = (page: string | null): number => {
  const pageNumber = parseInt(page || "1", 10);
  return pageNumber > 0 ? pageNumber : 1;
};

const convertGqlSortToTableSort = (sort: EtfSort): ShareListSort => {
  const direction = sort.direction.toLowerCase() as Lowercase<SortDirection>;
  switch (sort.field) {
    case EtfSortField.Name:
      return { field: TableSortField.Name, direction };
    case EtfSortField.WeeklyVolatility:
    case EtfSortField.MonthlyVolatility:
    case EtfSortField.QuarterlyVolatility:
    case EtfSortField.YearlyVolatility:
      return { field: TableSortField.Volatility, direction };
    case EtfSortField.WeeklyVolumeChangePercent:
    case EtfSortField.MonthlyVolumeChangePercent:
    case EtfSortField.QuarterlyVolumeChangePercent:
    case EtfSortField.YearlyVolumeChangePercent:
      return { field: TableSortField.VolumeChangePercent, direction };
    case EtfSortField.WeeklyPriceChangePercent:
    case EtfSortField.MonthlyPriceChangePercent:
    case EtfSortField.QuarterlyPriceChangePercent:
    case EtfSortField.YearlyPriceChangePercent:
      return { field: TableSortField.PriceChangePercent, direction };
  }
  throw new Error("Unknown sorting");
};

const tableSortToGqlSort = (
  period: SecurityPricePeriod,
  sort: ShareListSort
): EtfSort => {
  const direction = sort.direction.toUpperCase() as SortDirection;
  switch (sort.field) {
    case TableSortField.Name:
      return { field: EtfSortField.Name, direction };
    case TableSortField.Volatility:
      switch (period) {
        case SecurityPricePeriod.Week:
          return { field: EtfSortField.WeeklyVolatility, direction };
        case SecurityPricePeriod.Month:
          return { field: EtfSortField.MonthlyVolatility, direction };
        case SecurityPricePeriod.Quarter:
          return { field: EtfSortField.QuarterlyVolatility, direction };
        case SecurityPricePeriod.Year:
          return { field: EtfSortField.YearlyVolatility, direction };
      }
      break;
    case TableSortField.VolumeChangePercent:
      switch (period) {
        case SecurityPricePeriod.Week:
          return {
            field: EtfSortField.WeeklyVolumeChangePercent,
            direction,
          };
        case SecurityPricePeriod.Month:
          return {
            field: EtfSortField.MonthlyVolumeChangePercent,
            direction,
          };
        case SecurityPricePeriod.Quarter:
          return {
            field: EtfSortField.QuarterlyVolumeChangePercent,
            direction,
          };
        case SecurityPricePeriod.Year:
          return {
            field: EtfSortField.YearlyVolumeChangePercent,
            direction,
          };
      }
      break;
    case TableSortField.PriceChangePercent:
      switch (period) {
        case SecurityPricePeriod.Week:
          return {
            field: EtfSortField.WeeklyPriceChangePercent,
            direction,
          };
        case SecurityPricePeriod.Month:
          return {
            field: EtfSortField.MonthlyPriceChangePercent,
            direction,
          };
        case SecurityPricePeriod.Quarter:
          return {
            field: EtfSortField.QuarterlyPriceChangePercent,
            direction,
          };
        case SecurityPricePeriod.Year:
          return {
            field: EtfSortField.YearlyPriceChangePercent,
            direction,
          };
      }
  }
  throw new Error("Unknown sorting");
};

export const Etfs = () => {
  const [queryParameters, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    parsePage(queryParameters.get("page"))
  );
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState<SecurityFormData>(
    fetchFilter(queryParameters)
  );

  const [sort, setSorting] = useState<EtfSort>(
    fetchSortParams(queryParameters.get("sort"), filter.period)
  );

  const variables = {
    pagination: {
      offset,
      limit: DEFAULT_PAGE_SIZE,
    },
    sort,
    ...searchFormToGqlVariables(filter),
  };
  const { data, loading, error, fetchMore, refetch } = useEtfsQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { data: countData, refetch: refetchCount } = useEtfsCountQuery({
    variables,
  });

  if (loading || error) {
    return <GqlLoaderBar loading={loading} error={error} />;
  }
  if (!data) {
    return null;
  }
  const onChangePage = (page: number) => {
    queryParameters.set("page", page.toString());
    setSearchParams(queryParameters);

    setOffset((page - 1) * DEFAULT_PAGE_SIZE);
    setCurrentPage(page);
    fetchMore({
      variables: {
        pagination: {
          offset: (page - 1) * DEFAULT_PAGE_SIZE,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };

  const onChangeSort = (sort: ShareListSort) => {
    const gqlSort = tableSortToGqlSort(filter.period, sort);
    setSorting(gqlSort);
    const newVariables = {
      ...variables,
      pagination: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      sort: gqlSort,
    };
    queryParameters.set("sort", `${gqlSort.field}.${gqlSort.direction}`);
    queryParameters.delete("page");
    setSearchParams(queryParameters);
    refetch(newVariables);
  };

  const total = countData?.etfsCount ?? 0;

  const onSearchChange = (newFilter: SecurityFormData) => {
    const newVariables = {
      ...variables,
      pagination: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      ...searchFormToGqlVariables(newFilter),
    };
    setFilter(newFilter);
    queryParameters.delete("page");
    for (const [key, value] of Object.entries(newFilter)) {
      if (value) {
        if (Array.isArray(value)) {
          queryParameters.set(key, value.join("|"));
        } else {
          queryParameters.set(key, value);
        }
      } else {
        queryParameters.delete(key);
      }
    }
    setSearchParams(queryParameters);
    refetch(newVariables);
    refetchCount(newVariables);
  };

  return (
    <div>
      <h2>Etfs</h2>
      <SecuritySearchForm onChange={onSearchChange} values={filter} />
      <SharesList
        currentPage={currentPage}
        total={total}
        pageSize={DEFAULT_PAGE_SIZE}
        sort={convertGqlSortToTableSort(sort)}
        data={data.etfs}
        onChangePage={onChangePage}
        onChangeSort={onChangeSort}
      />
    </div>
  );
};
