import React from "react";
import Select from "react-select";
import { ExchangeCode } from "../../../graphql";

type ExchangeOption = {
  label: string;
  value: ExchangeCode;
};

type ExchangeProps = {
  value: ExchangeCode | undefined;
  onExchangeChange: (code: ExchangeCode) => void;
};
export function ExchangeSelect({ onExchangeChange, value }: ExchangeProps) {
  const options = [
    { label: "MOEX", value: ExchangeCode.Moex },
    { label: "RTS", value: ExchangeCode.Rts },
    { label: "OTC", value: ExchangeCode.Otc },
  ];

  const onChange = (option: ExchangeOption | null) => {
    if (option) {
      onExchangeChange(option.value as ExchangeCode);
    }
  };
  const defaultValue = options.find(
    ({ value: optionValue }) => optionValue === value
  );

  return (
    <Select
      placeholder={"Exchange"}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
    />
  );
}
