import * as React from "react";

export default function PercentChanged({ value }: { value: number }) {
  const className = value < 0 ? "text-danger" : "text-success";
  const icon = value > 0 ? "bi-arrow-up" : "bi-arrow-down";
  return (
    <span className={`price-change ${className}`}>
      <i className={`bi ${icon}`} />{value}%
    </span>
  );
}
