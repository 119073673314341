import React from "react";

export const ShareExternalLink = ({
  ticker,
  name,
}: {
  name: string;
  ticker: string;
}) => {
  return (
    <a
      href={`https://www.tinkoff.ru/invest/stocks/${ticker}/`}
      rel="noreferrer"
      target="_blank"
    >
      {name}
    </a>
  );
};
